import { FC } from "react";

type Props = {}

export const LogoSvg: FC<Props> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.4998 10.8825C73.4998 11.621 73.4255 12.3369 73.278 13.0315C72.2885 17.8168 68.0492 21.4058 62.976 21.4058C59.3133 21.4058 56.0935 19.5373 54.21 16.7017C53.0949 15.04 52.4521 13.039 52.4521 10.8825C52.4521 5.07027 57.1643 0.358712 62.976 0.358712C68.7882 0.358712 73.4998 5.07027 73.4998 10.8825ZM96.4626 29.8353L67.0925 52.3224C64.7586 54.1171 61.9668 54.9961 59.1906 54.9961C55.8303 54.9961 52.485 53.7111 49.9737 51.1929L42.4709 43.6895C43.8147 43.9625 45.2034 44.1031 46.6283 44.1031C52.0714 44.1031 57.0341 42.0128 60.7411 38.5788L87.4824 18.1077C90.7241 15.6338 95.3544 16.2467 97.8357 19.4814C100.317 22.7236 99.7043 27.354 96.4626 29.8353Z"
        fill="#83F1D7" />
      <path
        d="M60.7411 38.5787C57.0341 42.0128 52.0714 44.103 46.6283 44.103C45.2033 44.103 43.8146 43.9625 42.4708 43.6895L35.0931 36.3117C32.2056 33.4317 32.2056 28.7496 35.0931 25.8696C37.9805 22.9891 42.6552 22.9891 45.5357 25.8696L59.3305 39.6645L60.7411 38.5787Z"
        fill="#2BA3FB" />
      <path
        d="M73.4998 10.8825C73.4998 11.6209 73.4255 12.3369 73.2781 13.0315C72.2885 17.8168 68.0493 21.4058 62.976 21.4058C59.3133 21.4058 56.0935 19.5373 54.2101 16.7017C53.0949 15.04 52.4521 13.039 52.4521 10.8825C52.4521 5.07025 57.1643 0.358689 62.976 0.358689C68.7882 0.358689 73.4998 5.07025 73.4998 10.8825Z"
        fill="#4653F5" />
      <path
        d="M96.4626 29.8353L67.0925 52.3224C64.7586 54.1171 61.9668 54.9961 59.1906 54.9961C55.8303 54.9961 52.485 53.7111 49.9737 51.1929L42.4709 43.6895C43.8147 43.9625 45.2034 44.1031 46.6283 44.1031C52.0714 44.1031 57.0341 42.0128 60.7411 38.5788L87.4824 18.1077C90.7241 15.6338 95.3544 16.2467 97.8357 19.4814C100.317 22.7236 99.7043 27.354 96.4626 29.8353Z"
        fill="#83F1D7" />
      <g filter="url(#filter0_i_1_43)">
        <path
          d="M60.7411 38.5787C57.0341 42.0128 52.0714 44.103 46.6283 44.103C45.2033 44.103 43.8146 43.9625 42.4708 43.6895L35.0931 36.3117C32.2056 33.4317 32.2056 28.7496 35.0931 25.8696C37.9805 22.9891 42.6552 22.9891 45.5357 25.8696L59.3305 39.6645L60.7411 38.5787Z"
          fill="#2BA3FB" />
      </g>
      <path
        d="M58.7874 122.268C58.7874 121.529 58.8617 120.813 59.0091 120.119C59.9987 115.333 64.2379 111.744 69.3112 111.744C72.9744 111.744 76.1942 113.612 78.0771 116.448C79.1922 118.11 79.835 120.111 79.835 122.268C79.835 128.079 75.1234 132.791 69.3112 132.791C63.4995 132.791 58.7874 128.079 58.7874 122.268Z"
        fill="#4653F5" />
      <path
        d="M35.8252 103.315L65.1952 80.8272C67.5285 79.0331 70.3203 78.1541 73.0972 78.1541C76.4569 78.1541 79.8022 79.4391 82.3135 81.9573L89.8163 89.4601C88.4725 89.1871 87.0838 89.0466 85.6588 89.0466C80.2158 89.0466 75.2537 91.1368 71.546 94.5708L44.8048 115.042C41.5631 117.516 36.9328 116.903 34.4514 113.668C31.9701 110.427 32.5829 105.796 35.8252 103.315Z"
        fill="#83F1D7" />
      <g filter="url(#filter1_i_1_43)">
        <path
          d="M71.546 94.5709C75.2536 91.1368 80.2158 89.0466 85.6588 89.0466C87.0838 89.0466 88.4725 89.1871 89.8163 89.4601L97.1941 96.8379C100.081 99.7184 100.081 104.4 97.1941 107.281C94.3067 110.16 89.632 110.16 86.752 107.281L72.9566 93.4851L71.546 94.5709Z"
          fill="#2BA3FB" />
      </g>
      <path
        d="M29.4041 36.2564L51.8911 65.6265C53.6859 67.9598 54.5648 70.7516 54.5648 73.5284C54.5648 76.8881 53.2798 80.234 50.761 82.7447L43.2583 90.2481C43.5313 88.9038 43.6718 87.5156 43.6718 86.0901C43.6718 80.6476 41.5816 75.6849 38.1475 71.9773L17.6764 45.2366C15.2026 41.9944 15.8154 37.364 19.0502 34.8827C22.2924 32.4013 26.9227 33.0142 29.4041 36.2564Z"
        fill="#83F1D7" />
      <path
        d="M38.1475 71.9773C41.5815 75.6849 43.6718 80.6476 43.6718 86.0901C43.6718 87.5156 43.5313 88.9038 43.2582 90.2481L35.8804 97.6253C33.0005 100.513 28.3183 100.513 25.4384 97.6253C22.5579 94.7379 22.5579 90.0632 25.4384 87.1833L39.2332 73.3879L38.1475 71.9773Z"
        fill="#2BA3FB" />
      <path
        d="M29.4041 36.2564L51.8911 65.6265C53.6859 67.9598 54.5648 70.7516 54.5648 73.5284C54.5648 76.8881 53.2798 80.234 50.761 82.7447L43.2583 90.2481C43.5313 88.9038 43.6718 87.5156 43.6718 86.0901C43.6718 80.6476 41.5816 75.6849 38.1475 71.9773L17.6764 45.2366C15.2026 41.9944 15.8154 37.364 19.0502 34.8827C22.2924 32.4013 26.9227 33.0142 29.4041 36.2564Z"
        fill="#83F1D7" />
      <g filter="url(#filter2_i_1_43)">
        <path
          d="M38.1475 71.9773C41.5815 75.6849 43.6718 80.6476 43.6718 86.0901C43.6718 87.5156 43.5313 88.9038 43.2582 90.2481L35.8804 97.6253C33.0005 100.513 28.3183 100.513 25.4384 97.6253C22.5579 94.7379 22.5579 90.0632 25.4384 87.1833L39.2332 73.3879L38.1475 71.9773Z"
          fill="#4653F5" />
      </g>
      <path
        d="M121.836 73.931C121.097 73.931 120.382 73.8573 119.687 73.7093C114.902 72.7197 111.313 68.4811 111.313 63.4072C111.313 59.7445 113.181 56.5248 116.017 54.6413C117.678 53.5262 119.68 52.884 121.836 52.884C127.648 52.884 132.36 57.5955 132.36 63.4072C132.36 69.2195 127.648 73.931 121.836 73.931Z"
        fill="#2BA3FB" />
      <path
        d="M10.5238 80.0471C9.78485 80.0471 9.0689 79.9733 8.37483 79.8253C3.58897 78.8358 0 74.5971 0 69.5232C0 65.8605 1.86851 62.6408 4.70408 60.7573C6.3658 59.6422 8.36735 59 10.5238 59C16.3355 59 21.0471 63.7115 21.0471 69.5232C21.0471 75.3355 16.3355 80.0471 10.5238 80.0471Z"
        fill="#2BA3FB" />
      <path
        d="M102.883 96.8938L80.396 67.5238C78.6013 65.1899 77.7229 62.3986 77.7229 59.6218C77.7229 56.2615 79.0079 52.9162 81.5261 50.4049L89.0289 42.9021C88.7559 44.2459 88.6154 45.6346 88.6154 47.0596C88.6154 52.5026 90.7056 57.4653 94.1396 61.1724L114.611 87.9136C117.085 91.1553 116.472 95.7862 113.237 98.2676C109.995 100.749 105.365 100.135 102.883 96.8938Z"
        fill="#83F1D7" />
      <g filter="url(#filter3_i_1_43)">
        <path
          d="M94.1396 61.1724C90.7056 57.4653 88.6154 52.5026 88.6154 47.0596C88.6154 45.6346 88.7559 44.2459 89.0289 42.9021L96.4067 35.5243C99.2866 32.6369 103.969 32.6369 106.849 35.5243C109.729 38.4123 109.729 43.087 106.849 45.9669L93.0539 59.7618L94.1396 61.1724Z"
          fill="#4653F5" />
      </g>
      <defs>
        <filter id="filter0_i_1_43" x="29.9275" y="19.7092" width="30.8136" height="24.3938"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="-3" dy="-4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_43" />
        </filter>
        <filter id="filter1_i_1_43" x="71.546" y="89.0466" width="30.8136" height="24.3939" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="3" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_43" />
        </filter>
        <filter id="filter2_i_1_43" x="20.278" y="71.9773" width="23.3938" height="29.8136" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="-3" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_43" />
        </filter>
        <filter id="filter3_i_1_43" x="88.6154" y="29.3588" width="24.3939" height="31.8136"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="4" dy="-4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_43" />
        </filter>
      </defs>
    </svg>
  );
};
